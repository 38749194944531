const categories = [
  { "id": 999, "name": "бөтенесе"},
  { "id": 1, "name": "Фонетика"},
  { "id": 2, "name": "Лексикология"},
  { "id": 3, "name": "Сүз ясалышы"},
  { "id": 4, "name": "Морфология"},
  { "id": 5, "name": "Синтаксис"},
  { "id": 6, "name": "Стилистика"},
  { "id": 7, "name": "Телләр классификациясе"}
]

export default categories
