'use client';
import React from 'react';
import styles from './tamyr.module.scss';
import {Link, useNavigate} from "react-router-dom";

const Page = () => {

    const router = useNavigate();

    const goBack = () => {
        router(-1)
    };

    return (
        <div className={`background`}>
            <div className='content'>
                <div className='header'>
                    <div className='header_block_border-wrap header_side'>
                        <div className='header_block'>
                            {/*<div className='green-button' onClick={goBack}>*/}
                            {/*    <img src="arrow-back.png" alt="" className='green-button_arrow'/>*/}
                            {/*    /!*<img src="АРТКА.svg" alt=""/>*!/*/}
                            {/*    АРТКА*/}
                            {/*</div>*/}
                            <img src="Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap header_center'>
                        <div className='header_block'>
                            <img src="Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <Link to='/instruction'>
                                <img src="Instruction.svg" className={`header_button`} alt="" style={{cursor: "pointer"}}/>
                            </Link>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className='header_block header_block_header'>
                            <img src="Уку- яктылык, укымау- караңгылык!.svg" alt=""/>
                        </div>
                    </div>
                    <Link to='/instruction'>
                        <div className='header_block_border-wrap header_side'>
                            <div className='header_block'>
                                {/*<div className='green-button'>*/}
                                {/*    /!*<img src="Инструкция.svg" alt=""/>*!/*/}
                                {/*    Инструкция*/}
                                {/*</div>*/}
                                <img src="Instruction.svg" className={`header_button`} alt="" style={{cursor: "pointer"}}/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={`${styles.content}`}>
                    <div className={`${styles.block} ${styles.block_pink}`}>
                        <div className={`${styles.round} ${styles.round_pink}`}>
                        </div>
                        <img src="Frame 4.svg" alt=""/>
                        <div className={styles.tamyrlogo}>
                            <img src="image 10.png" alt=""/>
                        </div>
                        <div className={`${styles.block_text} ${styles.block_pink_text_pink}`}>
                            Бу әсбапта тел гыйлеменең фонетика, лексикология, сүз ясалышы, морфология, синтаксис, стилистика үлекләренә караган кагыйдәләр, телләр классификациясе таблицаларда бирелә.
                        </div>
                        <Link to='/tamyr/tables'  state={{ cards: 'first' }}>
                            <div className={`orange-button ${styles.orange_button}`}>
                                <img src="АЛГА.svg" alt=""/>
                            </div>
                        </Link>
                    </div>
                    <div className={`${styles.block} ${styles.block_green}`}>
                        <div className={`${styles.round} ${styles.round_green}`}>
                        </div>
                        <img src="таблицаларда.svg" alt=""/>
                        <div className={styles.tamyrlogo}>
                            <img src="logo2 2.png" alt=""/>
                        </div>
                        <div className={`${styles.block_text} ${styles.block_green_text_green}`}>
                            Бу әсбапта татар әдәбияты теориясенә караган кагыйдәләр, әсәрләрдән алынган мисаллар таблицаларда бирелә.
                        </div>
                        <Link to='/tamyr/tables'  state={{ cards: 'second' }}>
                            <div className={`orange-button ${styles.orange_button}`}>
                                <img src="АЛГА.svg" alt=""/>
                            </div>
                        </Link>
                    </div>
                    <div className={`${styles.block} ${styles.block_yellow}`}>
                        <div className={`${styles.round} ${styles.round_yellow}`}>
                        </div>
                        <img src="тестлар.svg" alt=""/>
                        <img src="ico+test@2x 1.svg" alt="" style={{marginTop: '58px', marginBottom: '15px'}}/>
                        <div className={`${styles.block_text} ${styles.block_yellow_text_yellow}`}>
                            Белемеңне сынап кара
                        </div>
                        <Link to='/tamyr/tests'>
                            <div className={`orange-button ${styles.orange_button}`}>
                                <img src="АЛГА.svg" alt=""/>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
