const testsFS = {
  "first": [
    {
      "id": 1,
      "question": "Фонетика тел белеменең нинди өлешен өйрәнә?",
      "answers": [       
        {"answer": "Телдәге сүзләр составын", "correct": false},
        {"answer": "Сүзләрнең төзелешен, сүз төркемнәрен", "correct": false},
        {"answer": "Сүзтезмәләрне, җөмләләрне, текстны", "correct": false},
		 {"answer": "Авазларның әйтелешен, язылышын", "correct": true}
      ]
    },
    {
      "id": 2,
      "question": "Татар әлибасында ничә хәреф бар?",
      "answers": [        
        {"answer": "33", "correct": false},
		{"answer": "39", "correct": true},
        {"answer": "26", "correct": false},
        {"answer": "36", "correct": false}
      ]
    },
    {
      "id": 3,
      "question": "Нәрсә ул орфография?",
      "answers": [        
        {"answer": "Сүзләрне ишетелгәнчә язу", "correct": false},
		{"answer": "Дөрес язу нормалары һәм кагыйдәләре", "correct": true},
        {"answer": "Алынма сүзләрне өйрәнү", "correct": false},
        {"answer": "Басым кагыйдәләрен өйрәнү", "correct": false}
      ]
    },
    {
      "id": 4,
      "question": "Боларның  кайсы интонация компонентларына керми?",
      "answers": [
        {"answer": "Сингармонизм", "correct": true},
        {"answer": "Фраза басымы", "correct": false},
        {"answer": "Пауза", "correct": false},
        {"answer": "Сөйләм көе", "correct": false}
      ]
    },
    {
      "id": 5,
      "question": "Лексикология тел белеменең нинди өлешен өйрәнә?",
      "answers": [        
        {"answer": "Сүзтезмәләрне, җөмләләрне, текстны", "correct": false},
		{"answer": "Телдәге сүзләр составын", "correct": true},
        {"answer": "Сүзләрнең төзелешен, сүз төркемнәрен", "correct": false},
        {"answer": "Авазларның әйтелешен, язылышын", "correct": false}
      ]
    },
    {
      "id": 6,
      "question": "Түбәндәге сүзләр арасында кайсы алынма сүз түгел?",
      "answers": [        
        {"answer": "Дөнья", "correct": false},
        {"answer": "Интернет", "correct": false},
		{"answer": "Бабай", "correct": true},
        {"answer": "Эшләпә", "correct": false}
      ]
    },
    {
      "id": 7,
      "question": "“Баш вату” сүзтезмәсе ул - ",
      "answers": [
        {"answer": "Фразеологик әйтелмә", "correct": true},
        {"answer": "Мәкаль", "correct": false},
        {"answer": "Идеографик синоним", "correct": false},
        {"answer": "Омограф", "correct": false}
      ]
    },
    {
      "id": 8,
      "question": "“Май” (елның бер ае) һәм “май” (ризык) нинди сүзләр дип атала?",
      "answers": [        
        {"answer": "Синонимнар", "correct": false},
        {"answer": "Антонимнар", "correct": false},
		{"answer": "Омонимнар", "correct": true},
        {"answer": "Фразеологик әйтелмәләр", "correct": false}
      ]
    },
    {
      "id": 9,
      "question": "Татар телендә сүз ясалышының ничә ысулы бар?",
      "answers": [        
        {"answer": "4", "correct": false},
        {"answer": "5", "correct": false},
        {"answer": "6", "correct": false},
		{"answer": "8", "correct": true}
      ]
    },
    {
      "id": 10,
      "question": "Түбәндәге сүзләрнең кайсы кушма сүз түгел?",
      "answers": [
        {"answer": "КФУ", "correct": true},
        {"answer": "Ташбака", "correct": false},
        {"answer": "Үги ана яфрагы", "correct": false},
        {"answer": "Көн-төн", "correct": false}
      ]
    },
    {
      "id": 11,
      "question": "“Халыкара” сүзенең ясалышы нинди? Ул кайсы сүз төркеменә карый?",
      "answers": [        
        {"answer": "Кушма исем", "correct": false},
		{"answer": "Кушма сыйфат", "correct": true},
        {"answer": "Ясалма фигыль", "correct": false},
        {"answer": "Тамыр сыйфат", "correct": false}
      ]
    },
    {
      "id": 12,
      "question": "Морфология тел белеменең нинди өлешен өйрәнә?",
      "answers": [        
        {"answer": "Сүзтезмәләрне, җөмләләрне, текстны", "correct": false},
        {"answer": "Телдәге сүзләр составын", "correct": false},
        {"answer": "Тел стильләрен", "correct": false},
		{"answer": "Сүзләрнең төзелешен, сүз төркемнәрен", "correct": true}
      ]
    },
    {
      "id": 13,
      "question": "Түбәндәге грамматик категорияләрнең кайсы исемгә хас түгел?",
      "answers": [        
        {"answer": "Килеш", "correct": false},
		{"answer": "Инфинитив", "correct": true},
        {"answer": "Тартым", "correct": false},
        {"answer": "Хәбәрлек", "correct": false}
      ]
    },
    {
      "id": 14,
      "question": "“Саргылт” сыйфаты кайсы дәрәҗәдә?",
      "answers": [        
        {"answer": "Гади", "correct": false},
        {"answer": "Артыклык", "correct": false},
		{"answer": "Кимлек", "correct": true},
        {"answer": "Чагыштыру", "correct": false}
      ]
    },
    {
      "id": 15,
      "question": "“Киләләр” фигыленең заты-саны нинди?",
      "answers": [        
        {"answer": "II зат, күплек сан", "correct": false},
        {"answer": "III зат, берлек сан", "correct": false},
        {"answer": "I зат, күплек сан", "correct": false},
		{"answer": "III зат, күплек сан", "correct": true}
      ]
    },
    {
      "id": 16,
      "question": "Туры сөйләм схемаларының кайсы дөрес түгел?",
      "answers": [        
        {"answer": "А: «Т».", "correct": false},
		{"answer": "«Т(?)(!) – а, – Т».", "correct": true},
        {"answer": "А: «Т», – а.", "correct": false},
        {"answer": "«Т(?)(!) – а. – Т».", "correct": false}
      ]
    },
    {
      "id": 17,
      "question": "“Болытлар(1) таралдылар да(2) ялтырап(3) кояш чыкты” җөмләсендә өтерләр кая куелырга тиеш?",
      "answers": [        
        {"answer": "1; 2; 3", "correct": false},
        {"answer": "2", "correct": false},
		{"answer": "2; 3", "correct": true},
        {"answer": "3", "correct": false}
      ]
    },
    {
      "id": 18,
      "question": "“Иртән табигать уяна” җөмләсендә иртән сүзе җөмләнең нинди кисәге була?",
      "answers": [
        {"answer": "Хәл", "correct": true},
        {"answer": "Аергыч", "correct": false},
        {"answer": "Хәбәр", "correct": false},
        {"answer": "Тәмамлык", "correct": false}
      ]
    },
    {
      "id": 19,
      "question": "Гариза нинди стильдә язылырга тиеш?",
      "answers": [        
        {"answer": "Әдәби стильдә", "correct": false},
        {"answer": "Публицистик стильдә", "correct": false},
        {"answer": "Фәнни стильдә", "correct": false},
		{"answer": "Рәсми стильдә", "correct": true}
      ]
    },
    {
      "id": 20,
      "question": "Татар теле нинди телләр төркеменә карый?",
      "answers": [        
        {"answer": "Роман телләре", "correct": false},
        {"answer": "Көнчыгыш славян телләре", "correct": false},
		{"answer": "Төрки телләр", "correct": true},
        {"answer": "Монгол телләре", "correct": false}
      ]
    }
  ],
  "second":[
    {
      "id": 1,
      "question": "Болгар чоры әдәбиятының иң киң таралган әсәре кайсы?",
      "answers": [
        {"answer": "«Кыйссаи Йосыф»", "correct": true},
        {"answer": "«Идегәй» дастаны", "correct": false},
        {"answer": "«Шүрәле» әкияте", "correct": false},
        {"answer": "«Моабит дәфтәрләре»", "correct": false}
      ]
    },
    {
      "id": 2,
      "question": "Түбәндәге әдәби төрләрнең кайсы матур әдәбият төрләренә керми?",
      "answers": [
        {"answer": "Публицистика", "correct": true},
        {"answer": "Лирик төр", "correct": false},
        {"answer": "Эпик төр", "correct": false},
        {"answer": "Драма", "correct": false}
      ]
    },
    {
      "id": 3,
      "question": "Мәдхия нинди жанрга карый?",
      "answers": [
        {"answer": "Лирик жанр", "correct": true},
        {"answer": "Эпик жанр", "correct": false},
        {"answer": "Драма жанры", "correct": false},
        {"answer": "Берсе дә түгел", "correct": false}
      ]
    },
    {
      "id": 4,
      "question": "Тормышны билгеле бер идеал, хыял яктылыгында үзгәртеп тасвирлый торган иҗат юнәлеше –",
      "answers": [
        {"answer": "Романтизм", "correct": true},
        {"answer": "Модернизм", "correct": false},
        {"answer": "Реализм", "correct": false},
        {"answer": "Берсе дә түгел", "correct": false}
      ]
    },
    {
      "id": 5,
      "question": "Яшәешнең асылын, төс һәм төсмерләрен бөтен нечкәлекләренә кадәр төгәл итеп гәүдәләндерә торган иҗат юнәлеше –",
      "answers": [
        {"answer": "Романтизм", "correct": false},
        {"answer": "Модернизм", "correct": false},
        {"answer": "Реализм", "correct": true},
        {"answer": "Берсе дә түгел", "correct": false}
      ]
    },
    {
      "id": 6,
      "question": "Түбәндәге юнәлешләрнең кайсы модернизмга керми?",
      "answers": [
        {"answer": "Символизм", "correct": false},
        {"answer": "Импрессионизм", "correct": false},
        {"answer": "Футуризм", "correct": false},
        {"answer": "Гыйсъянчылык", "correct": true}
      ]
    },
    {
      "id": 7,
      "question": "Конфликтның башлану ноктасы ничек атала?",
      "answers": [
        {"answer": "Төенләнеш", "correct": true},
        {"answer": "Пролог", "correct": false},
        {"answer": "Перипетияләр", "correct": false},
        {"answer": "Эпилог", "correct": false}
      ]
    },
    {
      "id": 8,
      "question": "Әсәрдәге вакыйгаларның, көрәшнең иң югары ноктасы ничек атала?",
      "answers": [
        {"answer": "Экспозиция", "correct": false},
        {"answer": "Чишелеш", "correct": false},
        {"answer": "Кульминация", "correct": true},
        {"answer": "Төенләнеш", "correct": false}
      ]
    },
    {
      "id": 9,
      "question": "Капма-каршы куелуга нигезләнгән стилистик фигура –",
      "answers": [
        {"answer": "Инверсия", "correct": false},
        {"answer": "Антитеза", "correct": true},
        {"answer": "Градация", "correct": false},
        {"answer": "Риторик сорау", "correct": false}
      ]
    },
    {
      "id": 10,
      "question": "Аерым төбәкләрдә, җирле сөйләмдә генә кулланыла торган сүзләр –",
      "answers": [
        {"answer": "Диалектизмнар", "correct": true},
        {"answer": "Калька", "correct": false},
        {"answer": "Һөнәрчелек сүзләре", "correct": false},
        {"answer": "Алынма сүзләр", "correct": false}
      ]
    },
    {
      "id": 11,
      "question": "Чиктән тыш арттырып сүрәтләүдән гыйбарәт тел-сүрәтләү чарасы –",
      "answers": [
        {"answer": "Гипербола", "correct": true},
        {"answer": "Аллегория", "correct": false},
        {"answer": "Эпитет", "correct": false},
        {"answer": "Метонимия", "correct": false}
      ]
    },
    {
      "id": 12,
      "question": "Күренешләрнең охшашлыгына яки каршылыгына нигезләнгән яшерен чагыштыру –",
      "answers": [
        {"answer": "Гипербола", "correct": false},
        {"answer": "Эпитет", "correct": false},
        {"answer": "Метафора", "correct": true},
        {"answer": "Литота", "correct": false}
      ]
    },
    {
      "id": 13,
      "question": "Астыртын көлү, мактау аркылы асылда хурлау –",
      "answers": [
        {"answer": "Метафора", "correct": false},
        {"answer": "Юмор", "correct": false},
        {"answer": "Ирония", "correct": true},
        {"answer": "Аллегория", "correct": false}
      ]
    },
    {
      "id": 14,
      "question": "Сүзләрнең шигырь юлларында аваздаш, бердәй яңгырашы –",
      "answers": [
        {"answer": "Рифма", "correct": true},
        {"answer": "Тезмә", "correct": false},
        {"answer": "Ритм", "correct": false},
        {"answer": "Строфа", "correct": false}
      ]
    },
    {
      "id": 15,
      "question": "Беренче тезмәнең – өченче, икенче тезмәнең дүртенчесе белән рифмалашуы (абаб) –",
      "answers": [
        {"answer": "Янәшә рифма", "correct": false},
        {"answer": "Аралаш рифма", "correct": true},
        {"answer": "Камаулы рифма", "correct": false},
        {"answer": "Юларалаш рифма", "correct": false}
      ]
    },
    {
      "id": 16,
      "question": "Әдәби кечерәйтүдән гыйбарәт булган тел-сурәтләү чарасы –",
      "answers": [
        {"answer": "Сынландыру", "correct": false},
        {"answer": "Сарказм", "correct": false},
        {"answer": "Литота", "correct": true},
        {"answer": "Метафора", "correct": false}
      ]
    },
    {
      "id": 17,
      "question": "Җөмләдә сүзләрнең урынын алыштыру –",
      "answers": [
        {"answer": "Көтелмәгәнлек", "correct": false},
        {"answer": "Оксиморон", "correct": false},
        {"answer": "Инверсия", "correct": true},
        {"answer": "Антитеза", "correct": false}
      ]
    },
    {
      "id": 18,
      "question": "Кайсы алым психологизм алымнарына керми?",
      "answers": [
        {"answer": "Эчке монолог", "correct": false},
        {"answer": "Сүз уйнату", "correct": true},
        {"answer": "Үзанализ", "correct": false},
        {"answer": "Аң-фикер агышы", "correct": false}
      ]
    },
    {
      "id": 19,
      "question": "Халык, милләт тарихы белән килешмәүдән гыйбарәт конфликт кайсы төргә карый?",
      "answers": [
        {"answer": "Кеше – Җәмгыять", "correct": false},
        {"answer": "Кеше – Тарих", "correct": true},
        {"answer": "Кеше – Вакыт", "correct": false},
        {"answer": "Кеше – Тирәлек", "correct": false}
      ]
    },
    {
      "id": 20,
      "question": "Әдәби әсәрдә җәмгыять тормышындагы аерым бер яклар белән бәйле проблема –",
      "answers": [
        {"answer": "Милли проблема", "correct": false},
        {"answer": "Иҗтимагый-сәяси проблема", "correct": true},
        {"answer": "Фәлсәфи проблема", "correct": false},
        {"answer": "Кеше преблемасы", "correct": false}
      ]
    }
  ]


}

export default testsFS