
import styles from './table.module.scss';
import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import AudioButton from "../../../../components/AudioButton";
import first from "../../../../config/first";
import second from "../../../../config/second";

const Page = () => {
    const router = useNavigate();    const location = useLocation();
    const imageContainerRef = useRef<HTMLImageElement>(null);
    const [audioButtonStyles, setAudioButtonStyles] = useState([]);
    const [currentSlide, setCurrentSlide] = useState<any>(null);
    const [paused, setPaused] = useState<boolean>(true);
    const audioRef = useRef(new Audio());
    const [currentAudio, setCurrentAudio] = useState(0);
    const [hidden, setHidden] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const currentAudioDurationRef = useRef<any>(null)
    const [customPlaying, setCustomPlaying] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [magnified, setMagnified] = React.useState(0);
    const { slide, cards } = location.state || { slide: { audio: [] } }; // Default empty slide if not exist
    const [ inFavorites, setInFavorites ] = useState(false); // Default empty slide if not exist

    const goBack = () => {
        router(-1);
    };
    const _handleDynamicButtonPress = () => {
        console.log(' handle ab')
        setPaused(prevPaused => {
            if (prevPaused) {
                audioRef.current.play()
            } else {
                audioRef.current.pause()
            }
            return !prevPaused
        });

        setCustomPlaying(true)
    }

    useEffect(() => {
        if (imageContainerRef.current) {
            const { width, height } = imageContainerRef.current.getBoundingClientRect();
            const newStyles = currentSlide?.audio.map((item: { coords: { y: number; x: number; }; }) => ({
                position: 'absolute',
                top: `${(height / 350) * item.coords.y}px`, // Assuming baseHeight is 350
                left: `${(width / 580) * item.coords.x}px` // Assuming baseWidth is 580
            }));
            if (magnified > 0) {
                newStyles.top = newStyles.top + newStyles.top * magnified
                newStyles.left = newStyles.left + newStyles.left * magnified
            }
            setAudioButtonStyles(newStyles);
        }
    }, [currentSlide?.audio]); // Depend on slide.audio

    const goNext = () => {
        console.log('go next', cards)
        if (cards && cards === 'first') {
            const currentSlideIndex = first.findIndex(x=>x.id === currentSlide.id)
            console.log(currentSlideIndex)
            if (currentSlideIndex !== -1) {
                if (currentSlideIndex+1<first.length-1) {
                    setCurrentSlide(first[currentSlideIndex+1])
                }
            }
        }
        if (cards && cards === 'second') {
            const currentSlideIndex = second.findIndex(x=>x.id === currentSlide.id)
            console.log(currentSlideIndex)
            if (currentSlideIndex !== -1) {
                if (currentSlideIndex+1<second.length-1) {
                    setCurrentSlide(second[currentSlideIndex+1])
                }
            }
        }
    }
    const goPrev = () => {
        console.log('go prev', cards)
        if (cards && cards === 'first') {
            const currentSlideIndex = first.findIndex(x=>x.id === currentSlide.id)
            console.log(currentSlideIndex)
            if (currentSlideIndex !== -1) {
                if (currentSlideIndex > 0) {
                    setCurrentSlide(first[currentSlideIndex-1])
                }
            }
        }
        if (cards && cards === 'second') {
            const currentSlideIndex = second.findIndex(x=>x.id === currentSlide.id)
            console.log(currentSlideIndex)
            if (currentSlideIndex !== -1) {
                if (currentSlideIndex > 0) {
                    setCurrentSlide(second[currentSlideIndex-1])
                }
            }
        }
    }
    // Update audio source and play status when currentAudio changes
    useEffect(() => {
        console.log(currentAudio)
        if (currentSlide && currentSlide.audio[currentAudio]) {
            audioRef.current.src = currentSlide.audio[currentAudio].file;
            audioRef.current.load();
            audioRef.current.addEventListener('ended', handleAudioEnd);
            if (!hidden) {
                audioRef.current.play().catch(e => console.error('Error playing audio:', e));
                setIsPlaying(true);
            }
        }
        return () => {
            audioRef.current.removeEventListener('ended', handleAudioEnd);
        };
    }, [currentAudio, hidden, currentSlide?.audio]);

    const handleAudioEnd = () => {
        console.log("Current track index before update:", currentAudio);
        if (currentAudio < currentSlide.audio.length - 1) {
            setCurrentAudio(current => {
                console.log("Updating current track from", current, "to", current + 1);
                return current + 1;
            });
        } else {
            setIsPlaying(false);
            console.log("Stopped playing as it's the last audio");
        }
    };

    const increaseMagnify = () => {
        if (magnified >= 2) {
            return
        }
        setMagnified(old=> parseFloat((old + 0.2).toFixed(1)))
    }

    const decreaseMagnify = () => {
        if (magnified < 0.1) {
            return
        }
        setMagnified(old=> parseFloat((old - 0.2).toFixed(1)))
    }

    const cardInFavorites = async () => {
        let storage = await localStorage.getItem('@favorites')
        if (storage) {
            storage = JSON.parse(storage);
            // @ts-ignore
            if (cards && currentSlide && currentSlide.id && storage[cards]) {
                // @ts-ignore
                if (storage[cards].indexOf(currentSlide.id) !== -1) {
                    setInFavorites(true)
                }
            }
        }
    }

    const removeFromFavorites = async () => {
        // Предполагаем, что cards и currentSlide._id доступны через состояние
        const slideId = currentSlide.id;

        // Получаем текущие избранные из localStorage
        const rawStorage = await localStorage.getItem('@favorites');
        // Парсим полученные данные или инициализируем пустым объектом, если данных нет
        const storage: { first?: string[], second?: string[] } = rawStorage ? JSON.parse(rawStorage) : {};

        // Проверяем, существует ли массив для указанного типа карточек и удаляем элемент из него
        // @ts-ignore
        if (storage[cards]) {
            // @ts-ignore
            const index = storage[cards].indexOf(slideId);
            if (index !== -1) {
                // @ts-ignore
                storage[cards].splice(index, 1);
            }
        }

        // Сохраняем обновлённый объект обратно в localStorage
        localStorage.setItem('@favorites', JSON.stringify(storage));
        setInFavorites(false)
    }


    const addInFavorites = async () => {
        // Получаем элемент из localStorage
        const rawStorage = await localStorage.getItem('@favorites');
        console.log(rawStorage)
        // Инициализируем storage как объект с потенциально существующими массивами first и second
        const storage: { first?: string[], second?: string[] } = rawStorage ? JSON.parse(rawStorage) : {};
        console.log(storage)
        // Проверяем, задан ли тип карточек и существует ли currentSlide с ID
        console.log(cards, currentSlide?.id);
        if (cards && currentSlide?.id) {
            console.log(cards, currentSlide?.id);
            // Переменная key теперь строго типизирована как 'first' или 'second'
            if (cards === 'first' || cards === 'second') {
                const key: 'first' | 'second' = cards;

                // Убедимся, что storage[key] определён как массив, и добавляем ID
                storage[key] = storage[key] || [];
                // @ts-ignore
                storage[key].push(currentSlide.id);

                // Сохраняем обновленный объект обратно в localStorage
                localStorage.setItem('@favorites', JSON.stringify(storage));
                setInFavorites(true)
            }
        }
    }


    useEffect(() => {
        cardInFavorites()
    }, [currentSlide, cards]);

    useEffect(() => {
        setCurrentSlide(slide)
    }, [slide]);

    // Update progress as audio plays
    useEffect(() => {
        const updateProgress = () => {
            const duration = audioRef.current.duration;
            if (duration) {
                setProgress((audioRef.current.currentTime / duration) * 100);
            }
        };
        audioRef.current.addEventListener('timeupdate', updateProgress);
        return () => {
            audioRef.current.removeEventListener('timeupdate', updateProgress);
        };
    }, []);

    const toggleAudioPlayback = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        console.log(magnified)
    }, [magnified]);

    useEffect(() => {
        return () =>  audioRef.current.pause()
    }, []);

    return (
        <div className={`background`}>
            <div className='content'>
                <div className='header'>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../../Back.svg" alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <img className={`${styles.header_button}`} src="../../татар теле.svg" alt=""/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className='header_block'>
                            <div className={styles.buttons}>
                                <div className={styles.button}>
                                    {!isPlaying && (
                                        <>
                                            <img onClick={() => {
                                                setHidden(old => !old);
                                                setIsPlaying(true)
                                            }} src="../../Иконка громкости.svg" alt=""/>
                                            <div className={styles.button_text}>
                                                Сөйләтү
                                            </div>
                                        </>
                                    )}
                                    {isPlaying && (
                                        <>
                                            <img onClick={() => {
                                                setHidden(old => !old);
                                                setIsPlaying(false)
                                            }} src="/bigPaused.svg" alt=""/>
                                            <div className={styles.button_text}>
                                                сөйләтү
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className={styles.button} onClick={decreaseMagnify}>
                                    <img src="../../лупа минус.svg" alt=""/>
                                    <div className={styles.button_text}>
                                        Кечерәйтү
                                    </div>
                                </div>
                                <div className={styles.button} onClick={increaseMagnify}>
                                    <img src="../../лупа плюс.svg" alt=""/>
                                    <div className={styles.button_text}>
                                        Зурайту
                                    </div>
                                </div>
                                    {!inFavorites && (
                                        <div className={styles.button} onClick={addInFavorites}>
                                            <img src="../../звезда.svg" alt=""/>
                                            <div className={styles.button_text}>
                                                Саклау
                                            </div>
                                        </div>
                                    )}
                                    {inFavorites && (
                                        <div className={styles.button} onClick={removeFromFavorites}>
                                            <img src="/emptyStar.svg" alt=""/>
                                            <div className={styles.button_text}>
                                                Саклау
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className='header_block_border-wrap header_side'>
                        <div className='header_block'>
                            <img src="../../татар теле.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.slide}>
                        <div className={styles.button} onClick={goPrev}><img src="../../Кнопка-лево.svg" alt=""/></div>
                        <div style={{
                            position: 'relative',
                            width: '80%',
                            maxHeight: '95vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'scroll'
                        }}>
                            <img ref={imageContainerRef} style={{width: `${100+100*magnified}%`, height: 'auto'}}
                                 src={currentSlide?.image}
                                 alt=""/>
                            {!hidden && currentSlide.audio.map((item: any, index: number) => (
                                <AudioButton key={index} percent={progress} playing={index===currentAudio} style={audioButtonStyles[index]}
                                             handlePress={() => {
                                                 if (index === currentAudio) {
                                                     _handleDynamicButtonPress();  // Call the function if it's the current audio
                                                 } else {
                                                     // Switch to another audio and update states
                                                     setCurrentAudio(index);
                                                     setProgress(0);
                                                     setPaused(false);
                                                     setCustomPlaying(true);
                                                 }
                                             }}/>
                            ))}
                        </div>
                        <div className={styles.button} onClick={goNext}><img src="../../Кнопка-право.svg" alt=""/></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
