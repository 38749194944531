const first = [
  {
    "id": 1,
    "category": 1,
    "text": "ФОНЕТИКА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio1.mp3",
        "coords": {
          "x": 371,
          "y": 170
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-1.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-1.jpg"
  },
  {
    "id": 2,
    "category": 1,
    "text": "АЛФАВИТ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio2-1.mp3",
        "coords": {
          "x": 437,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio2-2.mp3",
        "coords": {
          "x": 9,
          "y": 162
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio2-3.mp3",
        "coords": {
          "x": 112,
          "y": 288
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-2.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-2.jpg"
  },
  {
    "id": 3,
    "category": 1,
    "text": "ОРФОГРАФИЯ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio3-1.mp3",
        "coords": {
          "x": 440,
          "y": 6
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio3-2.mp3",
        "coords": {
          "x": 6,
          "y": 75
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio3-3.mp3",
        "coords": {
          "x": 249,
          "y": 178
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio3-4.mp3",
        "coords": {
          "x": 396,
          "y": 79
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio3-5.mp3",
        "coords": {
          "x": 510,
          "y": 139
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-3.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-3.jpg"
  },
  {
    "id": 4,
    "category": 1,
    "text": "Ъ ҺӘМ Ь ХӘРЕФЛӘРЕНЕҢ ДӨРЕС ЯЗЫЛЫШЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio4-1.mp3",
        "coords": {
          "x": 429,
          "y": 5
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio4-2.mp3",
        "coords": {
          "x": 18,
          "y": 65
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio4-3.mp3",
        "coords": {
          "x": 513,
          "y": 65
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-4.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-4.jpg"
  },
  {
    "id": 5,
    "category": 1,
    "text": "БАСЫМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio5-1.mp3",
        "coords": {
          "x": 407,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio5-2.mp3",
        "coords": {
          "x": 10,
          "y": 93
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio5-3.mp3",
        "coords": {
          "x": 526,
          "y": 93
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-5.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-5.jpg"
  },
  {
    "id": 6,
    "category": 1,
    "text": "СУЗЫК АВАЗЛАР",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-6.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-6.jpg"
  },
  {
    "id": 7,
    "category": 1,
    "text": "ТАРТЫК АВАЗЛАР 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio7-1.mp3",
        "coords": {
          "x": 101,
          "y": 2
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio7-2.mp3",
        "coords": {
          "x": 23,
          "y": 51
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio7-3.mp3",
        "coords": {
          "x": 402,
          "y": 51
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-7.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-7.jpg"
  },
  {
    "id": 8,
    "category": 1,
    "text": "ТАРТЫК АВАЗЛАР 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio8.mp3",
        "coords": {
          "x": 94,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-8.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-8.jpg"
  },
  {
    "id": 9,
    "category": 1,
    "text": "СУЗЫКЛАР ҺӘМ ТАРТЫКЛАР БЕЛӘН БӘЙЛЕ АВАЗ ҮЗГӘРЕШЛӘРЕ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio9.mp3",
        "coords": {
          "x": 77,
          "y": 19
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-9.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-9.jpg"
  },
  {
    "id": 10,
    "category": 1,
    "text": "СУЗЫКЛАР ҺӘМ ТАРТЫКЛАР БЕЛӘН БӘЙЛЕ АВАЗ ҮЗГӘРЕШЛӘРЕ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio10.mp3",
        "coords": {
          "x": 64,
          "y": 17
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-10.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-10.jpg"
  },
  {
    "id": 11,
    "category": 1,
    "text": "ИНТОНАЦИЯ ҺӘМ АНЫҢ ТӨП ӨЛЕШЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio11.mp3",
        "coords": {
          "x": 62,
          "y": 18
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-11.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-11.jpg"
  },
  {
    "id": 12,
    "category": 2,
    "text": "ЛЕКСИКОЛОГИЯ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio12.mp3",
        "coords": {
          "x": 399,
          "y": 139
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-12.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-12.jpg"
  },
  {
    "id": 13,
    "category": 2,
    "text": "ТАТАР ТЕЛЕНЕҢ СҮЗЛЕК СОСТАВЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio13-1.mp3",
        "coords": {
          "x": 102,
          "y": 7
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio13-2.mp3",
        "coords": {
          "x": 20,
          "y": 65
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio13-3.mp3",
        "coords": {
          "x": 193,
          "y": 124
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio13-4.mp3",
        "coords": {
          "x": 510,
          "y": 65
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-13.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-13.jpg"
  },
  {
    "id": 14,
    "category": 2,
    "text": "ФРАЗЕОЛОГИК ӘЙТЕЛМӘЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio14.mp3",
        "coords": {
          "x": 67,
          "y": 17
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-14.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-14.jpg"
  },
  {
    "id": 15,
    "category": 2,
    "text": "СИНОНИМНАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio15-1.mp3",
        "coords": {
          "x": 103,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio15-2.mp3",
        "coords": {
          "x": 20,
          "y": 60
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio15-3.mp3",
        "coords": {
          "x": 515,
          "y": 60
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-15.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-15.jpg"
  },
  {
    "id": 16,
    "category": 2,
    "text": "ОМОНИМНАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio16.mp3",
        "coords": {
          "x": 103,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-16.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-16.jpg"
  },
  {
    "id": 17,
    "category": 2,
    "text": "АНТОНИМНАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio17-1.mp3",
        "coords": {
          "x": 103,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio17-2.mp3",
        "coords": {
          "x": 7,
          "y": 69
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio17-3.mp3",
        "coords": {
          "x": 528,
          "y": 69
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-17.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-17.jpg"
  },
  {
    "id": 18,
    "category": 2,
    "text": "ИСКЕРГӘН СҮЗЛӘР ҺӘМ НЕОЛОГИЗМНАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio18-1.mp3",
        "coords": {
          "x": 103,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio18-2.mp3",
        "coords": {
          "x": 7,
          "y": 78
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio18-3.mp3",
        "coords": {
          "x": 525,
          "y": 78
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-18.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-18.jpg"
  },
  {
    "id": 19,
    "category": 2,
    "text": "ЯЛГЫЗЛЫК ИСЕМНӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio19.mp3",
        "coords": {
          "x": 91,
          "y": 12
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-19.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-19.jpg"
  },
  {
    "id": 20,
    "category": 3,
    "text": "СҮЗ ЯСАЛЫШЫ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio20.mp3",
        "coords": {
          "x": 397,
          "y": 152
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-20.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-20.jpg"
  },
  {
    "id": 21,
    "category": 3,
    "text": "СҮЗ ЯСАЛЫШЫ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio21.mp3",
        "coords": {
          "x": 88,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-21.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-21.jpg"
  },
  {
    "id": 22,
    "category": 3,
    "text": "СҮЗЛӘРНЕҢ СТРУКТУР ЯКТАН ТӨРЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio22.mp3",
        "coords": {
          "x": 107,
          "y": 5
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-22.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-22.jpg"
  },
  {
    "id": 23,
    "category": 3,
    "text": "ТАМЫР ҺӘМ КУШЫМЧА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio23-1.mp3",
        "coords": {
          "x": 83,
          "y": 2
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio23-2.mp3",
        "coords": {
          "x": 17,
          "y": 76
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio23-3.mp3",
        "coords": {
          "x": 520,
          "y": 76
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-23.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-23.jpg"
  },
  {
    "id": 24,
    "category": 3,
    "text": "ИСЕМНӘР ЯСАЛЫШЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio24.mp3",
        "coords": {
          "x": 89,
          "y": 15
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-24.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-24.jpg"
  },
  {
    "id": 25,
    "category": 3,
    "text": "ФИГЫЛЬЛӘР ЯСАЛЫШЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio25.mp3",
        "coords": {
          "x": 84,
          "y": 15
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-25.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-25.jpg"
  },
  {
    "id": 26,
    "category": 3,
    "text": "СЫЙФАТЛАР ЯСАЛЫШЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio26.mp3",
        "coords": {
          "x": 84,
          "y": 4
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-26.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-26.jpg"
  },
  {
    "id": 27,
    "category": 3,
    "text": "РӘВЕШЛӘР ЯСАЛЫШЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio27.mp3",
        "coords": {
          "x": 78,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-27.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-27.jpg"
  },
  {
    "id": 28,
    "category": 3,
    "text": "КУШМА СҮЗЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio28.mp3",
        "coords": {
          "x": 100,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-28.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-28.jpg"
  },
  {
    "id": 29,
    "category": 4,
    "text": "МОРФОЛОГИЯ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio29.mp3",
        "coords": {
          "x": 404,
          "y": 134
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-29.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-29.jpg"
  },
  {
    "id": 30,
    "category": 4,
    "text": "СҮЗ ТӨРКЕМНӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio30-1.mp3",
        "coords": {
          "x": 105,
          "y": 2
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio30-2.mp3",
        "coords": {
          "x": 16,
          "y": 52
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio30-3.mp3",
        "coords": {
          "x": 195,
          "y": 90
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio30-4.mp3",
        "coords": {
          "x": 513,
          "y": 52
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-30.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-30.jpg"
  },
  {
    "id": 31,
    "category": 4,
    "text": "ИСЕМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio31.mp3",
        "coords": {
          "x": 105,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-31.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-31.jpg"
  },
  {
    "id": 32,
    "category": 4,
    "text": "ИСЕМНӘРНЕҢ КИЛЕШ БЕЛӘН ТӨРЛӘНЕШЕ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-32.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-32.jpg"
  },
  {
    "id": 33,
    "category": 4,
    "text": "ИСЕМНӘРНЕҢ ТАРТЫМ БЕЛӘН ТӨРЛӘНЕШЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio33.mp3",
        "coords": {
          "x": 78,
          "y": 8
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-33.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-33.jpg"
  },
  {
    "id": 34,
    "category": 4,
    "text": "ТАРТЫМЛЫ ИСЕМНӘРНЕҢ КИЛЕШ БЕЛӘН ТӨРЛӘНЕШЕ 1",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-34.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-34.jpg"
  },
  {
    "id": 35,
    "category": 4,
    "text": "ИСЕМНӘРНЕҢ КИЛЕШ БЕЛӘН ТӨРЛӘНЕШЕ 2",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-35.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-35.jpg"
  },
  {
    "id": 36,
    "category": 4,
    "text": "СЫЙФАТ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio36.mp3",
        "coords": {
          "x": 105,
          "y": 4
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-36.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-36.jpg"
  },
  {
    "id": 37,
    "category": 4,
    "text": "СЫЙФАТ ДӘРӘҖӘЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio37-1.mp3",
        "coords": {
          "x": 88,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio37-2.mp3",
        "coords": {
          "x": 25,
          "y": 211
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-37.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-37.jpg"
  },
  {
    "id": 38,
    "category": 4,
    "text": "САН ТӨРКЕМЧӘЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio38.mp3",
        "coords": {
          "x": 88,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-38.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-38.jpg"
  },
  {
    "id": 39,
    "category": 4,
    "text": "АЛМАШЛЫК",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio39.mp3",
        "coords": {
          "x": 103,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-39.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-39.jpg"
  },
  {
    "id": 40,
    "category": 4,
    "text": "ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio40.mp3",
        "coords": {
          "x": 101,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-40.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-40.jpg"
  },
  {
    "id": 41,
    "category": 4,
    "text": "ФИГЫЛЬ ЮНӘЛЕШЛӘРЕ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-41.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-41.jpg"
  },
  {
    "id": 42,
    "category": 4,
    "text": "ЗАТЛАНЫШЛЫ ФИГЫЛЬ ТӨРКЕМЧӘЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio42.mp3",
        "coords": {
          "x": 93,
          "y": 35
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-42.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-42.jpg"
  },
  {
    "id": 43,
    "category": 4,
    "text": "ЗАТЛАНЫШСЫЗ ФИГЫЛЬ ТӨРКЕМЧӘЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio43.mp3",
        "coords": {
          "x": 89,
          "y": 37
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-43.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-43.jpg"
  },
  {
    "id": 44,
    "category": 4,
    "text": "ХИКӘЯ ФИГЫЛЬНЕҢ ЗАМАН БЕЛӘН ТӨРЛӘНЕШЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio44-1.mp3",
        "coords": {
          "x": 107,
          "y": 1
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio44-2.mp3",
        "coords": {
          "x": 44,
          "y": 58
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio44-3.mp3",
        "coords": {
          "x": 204,
          "y": 99
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio44-4.mp3",
        "coords": {
          "x": 499,
          "y": 58
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-44.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-44.jpg"
  },
  {
    "id": 45,
    "category": 4,
    "text": "ХӘЗЕРГЕ ЗАМАН ХИКӘЯ ФИГЫЛЬНЕҢ ЗАТ-САН БЕЛӘН ТӨРЛӘНЕШЕ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-45.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-45.jpg"
  },
  {
    "id": 46,
    "category": 4,
    "text": "ШАРТ ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio46.mp3",
        "coords": {
          "x": 105,
          "y": 4
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-46.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-46.jpg"
  },
  {
    "id": 47,
    "category": 4,
    "text": "БОЕРЫК ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio47.mp3",
        "coords": {
          "x": 88,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-47.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-47.jpg"
  },
  {
    "id": 48,
    "category": 4,
    "text": "СЫЙФАТ ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio48-1.mp3",
        "coords": {
          "x": 105,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio48-2.mp3",
        "coords": {
          "x": 8,
          "y": 62
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio48-3.mp3",
        "coords": {
          "x": 524,
          "y": 62
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio48-4.mp3",
        "coords": {
          "x": 185,
          "y": 211
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-48.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-48.jpg"
  },
  {
    "id": 49,
    "category": 4,
    "text": "ИСЕМ ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio49-1.mp3",
        "coords": {
          "x": 105,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio49-2.mp3",
        "coords": {
          "x": 381,
          "y": 64
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-49.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-49.jpg"
  },
  {
    "id": 50,
    "category": 4,
    "text": "ХӘЛ ФИГЫЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio50-1.mp3",
        "coords": {
          "x": 105,
          "y": 2
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio50-2.mp3",
        "coords": {
          "x": 14,
          "y": 79
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio50-3.mp3",
        "coords": {
          "x": 525,
          "y": 79
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio50-4.mp3",
        "coords": {
          "x": 117,
          "y": 220
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-50.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-50.jpg"
  },
  {
    "id": 51,
    "category": 4,
    "text": "ИНФИНИТИВ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio51.mp3",
        "coords": {
          "x": 99,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-51.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-51.jpg"
  },
  {
    "id": 52,
    "category": 4,
    "text": "РӘВЕШ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio52.mp3",
        "coords": {
          "x": 100,
          "y": 5
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-52.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-52.jpg"
  },
  {
    "id": 53,
    "category": 4,
    "text": "ИЯРТЕМНӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio53.mp3",
        "coords": {
          "x": 106,
          "y": 6
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-53.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-53.jpg"
  },
  {
    "id": 54,
    "category": 4,
    "text": "БӘЙЛЕКЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio54-1.mp3",
        "coords": {
          "x": 106,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio54-2.mp3",
        "coords": {
          "x": 20,
          "y": 71
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-54.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-54.jpg"
  },
  {
    "id": 55,
    "category": 4,
    "text": "БӘЙЛЕК СҮЗЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio55.mp3",
        "coords": {
          "x": 103,
          "y": 4
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-55.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-55.jpg"
  },
  {
    "id": 56,
    "category": 4,
    "text": "ТЕРКӘГЕЧЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio56-1.mp3",
        "coords": {
          "x": 105,
          "y": 3
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio56-2.mp3",
        "coords": {
          "x": 4,
          "y": 74
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-56.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-56.jpg"
  },
  {
    "id": 57,
    "category": 4,
    "text": "ЫМЛЫКЛАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio57.mp3",
        "coords": {
          "x": 105,
          "y": 3
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-57.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-57.jpg"
  },
  {
    "id": 58,
    "category": 4,
    "text": "КИСӘКЧӘЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio58.mp3",
        "coords": {
          "x": 103,
          "y": 8
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-58.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-58.jpg"
  },
  {
    "id": 59,
    "category": 5,
    "text": "СИНТАКСИС",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio59.mp3",
        "coords": {
          "x": 138,
          "y": 83
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-59.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-59.jpg"
  },
  {
    "id": 60,
    "category": 5,
    "text": "СҮЗТЕЗМӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio60-1.mp3",
        "coords": {
          "x": 107,
          "y": 4
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio60-2.mp3",
        "coords": {
          "x": 201,
          "y": 69
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-60.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-60.jpg"
  },
  {
    "id": 61,
    "category": 5,
    "text": "ГАДИ ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio61.mp3",
        "coords": {
          "x": 109,
          "y": 2
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-61.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-61.jpg"
  },
  {
    "id": 62,
    "category": 5,
    "text": "ҖӨМЛӘНЕҢ БАШ ҺӘМ ИЯРЧЕН КИСӘКЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio62.mp3",
        "coords": {
          "x": 84,
          "y": 26
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-62.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-62.jpg"
  },
  {
    "id": 63,
    "category": 5,
    "text": "ИЯ БЕЛӘН ХӘБӘР АРАСЫНА СЫЗЫК КУЮ ОЧРАКЛАРЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio63.mp3",
        "coords": {
          "x": 95,
          "y": 21
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-63.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-63.jpg"
  },
  {
    "id": 64,
    "category": 5,
    "text": "ХӘЛ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio64.mp3",
        "coords": {
          "x": 63,
          "y": 37
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-64.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-64.jpg"
  },
  {
    "id": 65,
    "category": 5,
    "text": "ТУРЫ ҺӘМ КЫЕК СӨЙЛӘМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio65.mp3",
        "coords": {
          "x": 76,
          "y": 5
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-65.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-65.jpg"
  },
  {
    "id": 66,
    "category": 5,
    "text": "КУШМА ҖӨМЛӘ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyr/page-66.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-66.jpg"
  },
  {
    "id": 67,
    "category": 5,
    "text": "ТЕЗМӘ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio67.mp3",
        "coords": {
          "x": 277,
          "y": 57
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-67.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-67.jpg"
  },
  {
    "id": 68,
    "category": 5,
    "text": "ИЯРЧЕНЛЕ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio68.mp3",
        "coords": {
          "x": 274,
          "y": 59
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-68.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-68.jpg"
  },
  {
    "id": 69,
    "category": 5,
    "text": "СИНТЕТИК ИЯРЧЕНЛЕ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio69-1.mp3",
        "coords": {
          "x": 127,
          "y": 1
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio69-2.mp3",
        "coords": {
          "x": 11,
          "y": 74
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio69-3.mp3",
        "coords": {
          "x": 505,
          "y": 74
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-69.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-69.jpg"
  },
  {
    "id": 70,
    "category": 5,
    "text": "АНАЛИТИК ИЯРЧЕНЛЕ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio70-1.mp3",
        "coords": {
          "x": 129,
          "y": 2
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio70-2.mp3",
        "coords": {
          "x": 25,
          "y": 78
        }
      },
      {
        "file": "/tamyr/audio/Tamyr/audio70-3.mp3",
        "coords": {
          "x": 493,
          "y": 78
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-70.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-70.jpg"
  },
  {
    "id": 71,
    "category": 5,
    "text": "КАТЛАУЛЫ ТӨЗЕЛМӘЛӘР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio71.mp3",
        "coords": {
          "x": 70,
          "y": 16
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-71.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-71.jpg"
  },
  {
    "id": 72,
    "category": 5,
    "text": "КҮП ИЯРЧЕНЛЕ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio72.mp3",
        "coords": {
          "x": 430,
          "y": 65
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-72.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-72.jpg"
  },
  {
    "id": 73,
    "category": 5,
    "text": "КҮП ТЕЗМӘЛЕ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio73.mp3",
        "coords": {
          "x": 197,
          "y": 82
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-73.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-73.jpg"
  },
  {
    "id": 74,
    "category": 5,
    "text": "КАТНАШ КУШМА ҖӨМЛӘ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio74.mp3",
        "coords": {
          "x": 79,
          "y": 6
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-74.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-74.jpg"
  },
  {
    "id": 75,
    "category": 6,
    "text": "СТИЛИСТИКА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio75.mp3",
        "coords": {
          "x": 384,
          "y": 170
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-75.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-75.jpg"
  },
  {
    "id": 76,
    "category": 6,
    "text": "СӨЙЛӘМ СТИЛЬЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio76.mp3",
        "coords": {
          "x": 6,
          "y": 152
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-76.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-76.jpg"
  },
  {
    "id": 77,
    "category": 7,
    "text": "ТЕЛЛӘР КЛАССИФИКАЦИЯСЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio77.mp3",
        "coords": {
          "x": 358,
          "y": 201
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-77.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-77.jpg"
  },
  {
    "id": 78,
    "category": 7,
    "text": "ТӨРКИ ТЕЛЛӘР 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio78.mp3",
        "coords": {
          "x": 425,
          "y": 33
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-78.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-78.jpg"
  },
  {
    "id": 79,
    "category": 7,
    "text": "ТӨРКИ ТЕЛЛӘР 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio79.mp3",
        "coords": {
          "x": 104,
          "y": 33
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-79.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-79.jpg"
  },
  {
    "id": 80,
    "category": 7,
    "text": "ҺИНД-ЕВРОПА ТЕЛЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio80.mp3",
        "coords": {
          "x": 274,
          "y": 202
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-80.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-80.jpg"
  },
  {
    "id": 81,
    "category": 7,
    "text": "ТЕЛЛӘРНЕҢ ГЕНЕАЛОГИК КЛАССИФИКАЦИЯСЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyr/audio81.mp3",
        "coords": {
          "x": 104,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyr/page-81.jpg",
    "minified": "/tamyr/images/Cards/Tamyr/minified/page-81.jpg"
  }
]

export default first