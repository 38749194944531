'use client';
import React, {useEffect, useRef, useState} from 'react';
import styles from './testpage.module.scss';
import {Link, useLocation, useNavigate} from "react-router-dom";
import store from "../../../../config/tests";




const Page = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {tests} = location.state
    const {first, second} = store;
    const router = useNavigate();
    const [questions, setQuestions] = useState<any[]>([]);
    const [answers, setAnswers] = useState<any[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const selectedAnswerRef = useRef<HTMLDivElement>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [answerStatus, setAnswerStatus] = useState<'correct' | 'incorrect' | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

    const goBack = () => {
        router(-1);
    };
    useEffect(() => {
        console.log(answers)
        if (answers && answers.length===10) {
            navigate('/tamyr/tests/testpage/resultpage', { state: {answers, tests} });
        }
    }, [answers]);

    function handleAnswer(index: number, answer: any) {
        if (activeIndex !== null) return;  // Prevent multiple selections

        setActiveIndex(index);
        console.log(answer);
        if (answers && answers.length>10) {
            return;
        }

        setTimeout(() => {
            const currentQuestionIndex = questions.findIndex(q => q.id === currentQuestion.id);
            setAnswers(oldAnswers => [...oldAnswers, answer.correct]);
            if (currentQuestionIndex === questions.length-1) {
                console.log('redirect')
                return
            }
            // Set timeout to update to the next question after another second
                setAnswerStatus(null); // Reset answer status
                setActiveIndex(null); // Reset active index for the new question
                // Find the index of the current question and increment to the next question
                const nextQuestionIndex = (currentQuestionIndex + 1) % questions.length;
                setCurrentQuestion(questions[nextQuestionIndex]); // Set the next question

        }, 300);
    }

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    };

    useEffect(() => {
        if (tests === 'first') {
            const shuffled = shuffleArray([...first]); // Clone and shuffle the 'first' array
            setQuestions(shuffled.slice(0, 10));  // Take the first 10 elements from the shuffled array
        } else if (tests === 'second') {
            const shuffled = shuffleArray([...second]); // Clone and shuffle the 'first' array
            setQuestions(shuffled.slice(0, 10));  // Assuming you want all questions from 'second'
        }
    }, [tests]);

    useEffect(() => {
        if (questions && questions.length) {
            setCurrentQuestion(questions[0])
        }
    }, [questions]);

    useEffect(() => {
        setActiveIndex(null)
        setCurrentQuestionIndex(questions.findIndex(q => q.id === currentQuestion.id));
    }, [currentQuestion]);

    useEffect(() => {

    }, []);

    return (
        <div className={`background`}>
            <div className='content'>
                <div className='header'>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../../Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <img src="../../тестлар2.svg" alt="" className={`${styles.header_button}`}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className='header_block'>
                            <div className={styles.rounds}>
                                {questions.map((question, index) => (
                                    <div className={`
                                    ${currentQuestionIndex === index
                                        ? styles.round_completed
                                        : index < answers.length
                                            ? answers[index]
                                                ? styles.round_correct
                                                : styles.round_incorrect
                                            : styles.round
                                    }
`} key={index}/>
                                ))}
                                {/*<div className={`${styles.round} ${styles.round_completed}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                                {/*<div className={`${styles.round}`}>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className='header_block_border-wrap header_side'>
                        <div className='header_block'>
                            <img src="../../тестлар2.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.question}>
                        {currentQuestion?.question}
                    </div>
                    {currentQuestion && currentQuestion.answers.map((item: any, index: number) => (
                        <div
                            className={`${styles.answer} ${index === activeIndex ? styles.answer_active : ''}`}
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleAnswer(index, item)}
                        >
                            {item.answer}
                        </div>
                    ))}
                    {/*<Link to='/tamyr/tests/testpage/resultpage'>*/}
                    {/*    <div className={`${styles.answer} ${styles.answer_active}`}>*/}
                    {/*        1. Әсәр геройлары арасында туган каршылык*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                    {/*<div className={`${styles.answer}`}>*/}
                    {/*    2. Персонажның үз-үзе белән каршылыкка керүе*/}
                    {/*</div>*/}
                    {/*<div className={`${styles.answer}`}>*/}
                    {/*    3. Халык, милләт тарихы белән килешмәү*/}
                    {/*</div>*/}
                    {/*<div className={`${styles.answer}`}>*/}
                    {/*    4. Геройның табигать белән гармониясе булмау, табигатьне саклауда битарафлык күрсәтү*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default Page;
