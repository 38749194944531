'use client';
import React, {useEffect, useState} from 'react';
import styles from './resultpage.module.scss';
import {useLocation, useNavigate} from "react-router-dom";


const Page = () => {

    const router = useNavigate();
    const location = useLocation();
    const [correct, setCorrect] = useState(0);
    const {answers, tests} = location.state
    const goBack = () => {
        router(-2);
    };

    const saveResults = async () => {
        const rawStorage = await localStorage.getItem('@results')
        //
        if (rawStorage ) {

            let storage = JSON.parse(rawStorage);
            if (tests && storage[tests]) {
                storage[tests].attempts +=1
                storage[tests].correct +=answers.filter((i: boolean) => i).length
                await localStorage.setItem('@results', JSON.stringify(storage));
            } else {
                storage[tests] = {attempts: 1, correct: answers.filter((i: boolean) => i).length};
                await localStorage.setItem('@results', JSON.stringify(storage));
            }

        } else {
            let storage: any = {
                first: {
                    attempts: 0,
                    correct: answers.filter((i: boolean) => i).length
                },
                second: {
                    attempts: 0,
                    correct: answers.filter((i: boolean) => i).length
                }
            }
            storage[tests].attempts++;
            await localStorage.setItem('@results', JSON.stringify(storage));
        }
    }

    useEffect(() => {
        console.log(answers)
       if (answers && answers.length) {
           console.log(answers.filter((i: boolean) => i))
           setCorrect(answers.filter((i: boolean) => i).length);
           saveResults()
       }
    }, [answers]);

    return (
        <div className={`background`}>
            <div className='content'>
                <div className='header'>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../../../Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <img src="../../../тестлар2.svg" alt="" className={`${styles.header_button}`}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className='header_block'>
                            <div className={styles.rounds}>
                                {location.state && location.state.answers && location.state.answers.map((i: boolean) => (
                                    <div className={`${i===true ? styles.round : styles.round_wrong}`}>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='header_block_border-wrap header_side'>
                        <div className='header_block'>
                            <img src="../../../тестлар2.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.header}>
                        Нәтиҗә
                    </div>
                    <div className={styles.answers}>
                        <div className={`${styles.answer}`}>
                            {correct}
                        </div>
                        <div className={`${styles.answer} ${styles.answer_wrong}`}>
                            {answers?.length - correct}
                        </div>
                    </div>
                    <div className={`orange-button ${styles.orange_button}`}>
                        <img src="../../../Кабатларга.svg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
