const second = [
  {
    "id": 1,
    "category": 1,
    "text": "ӘДӘБИЯТ БЕЛЕМЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio1.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-1.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-1.jpg"
  },
  {
    "id": 2,
    "category": 1,
    "text": "ТАТАР ӘДӘБИЯТЫНЫҢ ЧОРЛАРГА БҮЛЕНЕШЕ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio2.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-2.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-2.jpg"
  },
  {
    "id": 3,
    "category": 1,
    "text": "ТАТАР ӘДӘБИЯТЫНЫҢ ЧОРЛАРГА БҮЛЕНЕШЕ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio3.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-3.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-3.jpg"
  },
  {
    "id": 4,
    "category": 1,
    "text": "ӘДӘБИ АЛЫМ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio4.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-4.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-4.jpg"
  },
  {
    "id": 5,
    "category": 1,
    "text": "ӘДӘБИ АЛЫМ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio5.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
      
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-5.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-5.jpg"
  },
  {
    "id": 6,
    "category": 1,
    "text": "МАТУР ӘДӘБИЯТ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-6.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-6.jpg"
  },
  {
    "id": 7,
    "category": 1,
    "text": "ЛИРИКА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio7.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-7.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-7.jpg"
  },
  {
    "id": 8,
    "category": 1,
    "text": "ЛИРИК ЖАНРЛАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio8.mp3",
        "coords": {
          "x": 61,
          "y": 16
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-8.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-8.jpg"
  },
  {
    "id": 9,
    "category": 1,
    "text": "ШӘРКЫЙ (КӨНЧЫГЫШ) ЛИРИК ЖАНРЛАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio9.mp3",
        "coords": {
          "x": 476,
          "y": 33
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-9.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-9.jpg"
  },
  {
    "id": 10,
    "category": 1,
    "text": "ЭПИК ТӨР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio10.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-10.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-10.jpg"
  },
  {
    "id": 11,
    "category": 1,
    "text": "ЛИРО-ЭПИК ТӨРАРА ФОРМА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio11.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-11.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-11.jpg"
  },
  {
    "id": 12,
    "category": 2,
    "text": "ДРАМА ТӨРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio12.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-12.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-12.jpg"
  },
  {
    "id": 13,
    "category": 2,
    "text": "ИҖАТ ТИБЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio13.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-13.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-13.jpg"
  },
  {
    "id": 14,
    "category": 2,
    "text": "РОМАНТИЗМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio14.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-14.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-14.jpg"
  },
  {
    "id": 15,
    "category": 2,
    "text": "РЕАЛИЗМ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio15.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-15.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-15.jpg"
  },
  {
    "id": 16,
    "category": 2,
    "text": "РЕАЛИЗМ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio16.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-16.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-16.jpg"
  },
  {
    "id": 17,
    "category": 2,
    "text": "МОДЕРНИЗМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio17.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-17.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-17.jpg"
  },
  {
    "id": 18,
    "category": 2,
    "text": "СТИЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio18.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-18.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-18.jpg"
  },
  {
    "id": 19,
    "category": 2,
    "text": "ТЕМА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio19.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-19.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-19.jpg"
  },
  {
    "id": 20,
    "category": 3,
    "text": "КОНФЛИКТ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio20.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-20.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-20.jpg"
  },
  {
    "id": 21,
    "category": 3,
    "text": "КОНФЛИКТ ТӨРЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio21.mp3",
        "coords": {
          "x": 62,
          "y": 16
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-21.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-21.jpg"
  },
  {
    "id": 22,
    "category": 3,
    "text": "ПРОБЛЕМА",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio22.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-22.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-22.jpg"
  },
  {
    "id": 23,
    "category": 3,
    "text": "ПРОБЛЕМА ТӨРЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio23.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-23.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-23.jpg"
  },
  {
    "id": 24,
    "category": 3,
    "text": "ПАФОС",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio24.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-24.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-24.jpg"
  },
  {
    "id": 25,
    "category": 3,
    "text": "ПАФОС ТӨРЛӘРЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio25.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-25.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-25.jpg"
  },
  {
    "id": 26,
    "category": 3,
    "text": "СЮЖЕТ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio26.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-26.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-26.jpg"
  },
  {
    "id": 27,
    "category": 3,
    "text": "СЮЖЕТ ЭЛЕМЕНТЛАРЫ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio27.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-27.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-27.jpg"
  },
  {
    "id": 28,
    "category": 3,
    "text": "СЮЖЕТ ЭЛЕМЕНТЛАРЫ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio28.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-28.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-28.jpg"
  },
  {
    "id": 29,
    "category": 4,
    "text": "СЮЖЕТТАН ТЫШ ЭЛЕМЕНТЛАР 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio29.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-29.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-29.jpg"
  },
  {
    "id": 30,
    "category": 4,
    "text": "СЮЖЕТТАН ТЫШ ЭЛЕМЕНТЛАР 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio30.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-30.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-30.jpg"
  },
  {
    "id": 31,
    "category": 4,
    "text": "КОМПОЗИЦИЯ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio31.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-31.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-31.jpg"
  },
  {
    "id": 32,
    "category": 4,
    "text": "КОМПОЗИЦИОН ПРИНЦИПЛАР",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-32.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-32.jpg"
  },
  {
    "id": 33,
    "category": 4,
    "text": "КОМПОЗИЦИОН АЛЫМНАР",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio33.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-33.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-33.jpg"
  },
  {
    "id": 34,
    "category": 4,
    "text": "ӘДӘБИ ОБРАЗ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-34.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-34.jpg"
  },
  {
    "id": 35,
    "category": 4,
    "text": "КЕШЕ ОБРАЗЛАРЫ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-35.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-35.jpg"
  },
  {
    "id": 36,
    "category": 4,
    "text": "КЕШЕ ОБРАЗЫН ТУДЫРУ АЛЫМНАРЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio36.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-36.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-36.jpg"
  },
  {
    "id": 37,
    "category": 4,
    "text": "КЕШЕ ОБРАЗЫН ТУДЫРУ АЛЫМНАРЫ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio37.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-37.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-37.jpg"
  },
  {
    "id": 38,
    "category": 4,
    "text": "КЕШЕ ОБРАЗЫН ТУДЫРУ АЛЫМНАРЫ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio38.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-38.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-38.jpg"
  },
  {
    "id": 39,
    "category": 4,
    "text": "КЕШЕ ОБРАЗЫН ТУДЫРУ АЛЫМНАРЫ 3",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio39.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-39.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-39.jpg"
  },
  {
    "id": 40,
    "category": 4,
    "text": "ДЕТАЛЬ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio40.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-40.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-40.jpg"
  },
  {
    "id": 41,
    "category": 4,
    "text": "ПОРТРЕТ",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-41.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-41.jpg"
  },
  {
    "id": 42,
    "category": 4,
    "text": "ПЕЙЗАЖ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio42.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-42.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-42.jpg"
  },
  {
    "id": 43,
    "category": 4,
    "text": "ӘЙБЕРЛӘР ДӨНЬЯСЫ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio43.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-43.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-43.jpg"
  },
  {
    "id": 44,
    "category": 4,
    "text": "ПСИХОЛОГИЗМ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio44.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-44.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-44.jpg"
  },
  {
    "id": 45,
    "category": 4,
    "text": "ПСИХОЛОГИЗМ АЛЫМНАРЫ 1",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-45.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-45.jpg"
  },
  {
    "id": 46,
    "category": 4,
    "text": "ПСИХОЛОГИЗМ АЛЫМНАРЫ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio46.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-46.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-46.jpg"
  },
  {
    "id": 47,
    "category": 4,
    "text": "ӘДӘБИ ВАКЫТ ҺӘМ УРЫН (ХРОНОТОП) 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio47.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-47.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-47.jpg"
  },
  {
    "id": 48,
    "category": 4,
    "text": "ӘДӘБИ ВАКЫТ ҺӘМ УРЫН (ХРОНОТОП) 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio48.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-48.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-48.jpg"
  },
  {
    "id": 49,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio49.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-49.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-49.jpg"
  },
  {
    "id": 50,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio50.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-50.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-50.jpg"
  },
  {
    "id": 51,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 3",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio51.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-51.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-51.jpg"
  },
  {
    "id": 52,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 4",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio52.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-52.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-52.jpg"
  },
  {
    "id": 53,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 5",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio53.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-53.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-53.jpg"
  },
  {
    "id": 54,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 6",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio54.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-54.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-54.jpg"
  },
  {
    "id": 55,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 7",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio55.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-55.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-55.jpg"
  },
  {
    "id": 56,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 8",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio56.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-56.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-56.jpg"
  },
  {
    "id": 57,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 9",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio57.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-57.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-57.jpg"
  },
  {
    "id": 58,
    "category": 4,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 10",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio58.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-58.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-58.jpg"
  },
  {
    "id": 59,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 11",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio59.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-59.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-59.jpg"
  },
  {
    "id": 60,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 12",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio60.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-60.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-60.jpg"
  },
  {
    "id": 61,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 13",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio61.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-61.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-61.jpg"
  },
  {
    "id": 62,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 14",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio62.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-62.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-62.jpg"
  },
  {
    "id": 63,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 15",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio63.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-63.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-63.jpg"
  },
  {
    "id": 64,
    "category": 5,
    "text": "ТЕЛ-СУРӘТЛӘҮ ЧАРАЛАРЫ 16",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio64.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-64.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-64.jpg"
  },
  {
    "id": 65,
    "category": 5,
    "text": "ШИГЫРЬ ТӨЗЕЛЕШЕ",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio65.mp3",
        "coords": {
          "x": 16,
          "y": 66
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-65.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-65.jpg"
  },
  {
    "id": 66,
    "category": 5,
    "text": "ШИГЫРЬ ТӨЗЕЛЕШЕНӘ ХАС СЫЙФАТЛАР",
    "audio": [],
    "image": "/tamyr/images/Cards/Tamyrlar/page-66.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-66.jpg"
  },
  {
    "id": 67,
    "category": 5,
    "text": "РИФМА 1",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio67.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-67.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-67.jpg"
  },
  {
    "id": 68,
    "category": 5,
    "text": "РИФМА 2",
    "audio": [
      {
        "file": "/tamyr/audio/Tamyrlar/audio68.mp3",
        "coords": {
          "x": 62,
          "y": 10
        }
      }
    ],
    "image": "/tamyr/images/Cards/Tamyrlar/page-68.jpg",
    "minified": "/tamyr/images/Cards/Tamyrlar/minified/page-68.jpg"
  }
]

export default second