import styles from "./page.module.scss";
import {BrowserRouter, Link, Outlet, Route, Router, Routes} from "react-router-dom";
import RootLayout from "./layout";
import Main from "./screens/Main";
import Tamyr from "./screens/tamyr/page"
import Tables from "./screens/tamyr/tables/page"
import Table from "./screens/tamyr/tables/table/page"
import Tests from "./screens/tamyr/tests/page"
import Test from "./screens/tamyr/tests/testpage/page"
import Result from "./screens/tamyr/tests/testpage/resultpage/page"
import Instruction from './screens/instruction/page';

export default function Home() {
  return (
      <BrowserRouter>
      <RootLayout>
              <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path='/instruction' element={<Instruction/>}/>
                  <Route path="/tamyr" element={<Tamyr />} />
                  <Route path="/tamyr/tables" element={<Tables />} />
                  <Route path="/tamyr/tables/table" element={<Table />} />
                  <Route path="/tamyr/tests" element={<Tests />} />
                  <Route path="/tamyr/tests/testpage" element={<Test />} />
                  <Route path="/tamyr/tests/testpage/resultpage" element={<Result />} />
                  {/*<Route path="/about" element={<About />} />*/}
                  {/*<Route path="/contact" element={<Contact />} />*/}
              </Routes>
      </RootLayout>
      </BrowserRouter>
  );
}
