import React from 'react';
import styles from "../page.module.scss";
import {Link} from "react-router-dom";

const Main = () => {
    return (
        <main className={`${styles.main}`}>
            <div className={styles.first_row}>
                <img src="/cvetochek.svg" alt="" className={styles.cvetochek}/>
                <div className={styles.first_row_content}>
                    <div className={styles.tamyr}>
                        <div className={styles.tamyr_emblems}>
                            <img src="/image 10.png" alt=""/>
                            <img src="/logo2 2.png" alt=""/>
                        </div>
                        <div className={styles.tamyr_title}>
                            «ТАМЫР/Тамырлар» — Виртуальный Путеводитель по татарскому Языку
                        </div>
                        <Link to='/tamyr'>
                            <img className={styles.tamyr_button} src="/Кнопка.png" alt=""/>
                        </Link>
                    </div>
                    <div className={styles.girls_reading}>
                        <img src="/girls-reading-a-tatar-book.png" alt="girls-reading-a-tatar-book"/>
                    </div>
                    <div className={styles.mosque}>
                        <img src="/mosque.png" alt="Мечеть"/>
                    </div>
                    <div className={styles.moon}>
                        <img src="/moon.png" alt="moon"/>
                    </div>
                </div>
            </div>
            <div className={styles.second_row}>
                <img className={styles.second_row_image} src="/Картинка.png" alt="Картинка"/>
                <div className={styles.second_row_content}>
                    <div className={styles.frame55_wrapper}>
                        <img className={styles.frame55} src="/Frame 55.png" alt=""/>
                    </div>
                    <div className={styles.second_row_info}>
                        <h1>
                            Откройте Мир Татарского Языка вместе с нами
                        </h1>
                        <p>
                            Насладитесь интерактивными уроками, аудиоматериалами и играми, которые помогут вам
                            освоить татарский язык с
                            удовольствием. Скачайте «Тамыр/Тамырлар» прямо сейчас и
                            станьте частью увлекательного мира татарской культуры!
                        </p>
                        <div className={styles.download}>
                            <div className={styles.download_line}></div>
                            <p className={styles.download_sign}>Скачать приложение</p>
                            <div className={styles.download_line}></div>
                        </div>
                        <div className={styles.buttons}>
                            <Link className={styles.buttons_button}
                                  to={"https://apps.apple.com/us/app/%D1%82%D0%B0%D0%BC%D1%8B%D1%80-%D0%BB%D0%B0%D1%80/id6480413847"}>
                                <img src="/appstore.svg" alt="AppStore"/>
                            </Link>
                            <Link className={styles.buttons_button}
                                  to={"https://play.google.com/store/apps/details?id=ru.tamyrlar.balachak"}>
                                <img src="/googleplay.svg" alt="Google Play"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.third_row}>
                <img src="/blossoming-tulip 1.svg" className={styles.cvetochek2}/>
                <div className={styles.third_row_content}>
                    <div className={styles.about}>
                        <h1>
                            О проекте
                        </h1>
                        <p>
                            «Тамыр/Тамырлар» - это инновационный проект, созданный для тех, кто желает погрузиться в мир
                            татарского языка и культуры. Наш проект представляет собой уникальный путь обучения,
                            сочетающий в себе
                            передовые методики и интерактивные технологии. Мы стремимся сделать изучение татарского
                            языка
                            доступным и увлекательным для всех, кто интересуется этим богатым историческим и культурным
                            наследием.
                        </p>
                    </div>
                    <div className={styles.image}>
                        <img src="/Asset 42x.png" alt="emblem"/>
                    </div>
                </div>
            </div>
            <div className={styles.extra_row}>
                <div className={styles.extra_row_leftSide}>
                    <img src='/D.svg' alt=""/>
                    <p>
                        Автономная некоммерческая организация «Республиканский ресурсный центр по поддержке социально ориентированных некоммерческих организаций»
                    </p>
                </div>
                <div className={styles.extra_row_rightSide}>
                    Грант Раиса Республики Татарстан на развитие гражданского общества 2023
                </div>
            </div>
            <div className={styles.fourth_row}>
                <div className={styles.fourth_row_content}>
                    <div>
                        {/*<div className={styles.contacts}>*/}
                        {/*    <div className={styles.contacts_header}>*/}
                        {/*        Контакты*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.contacts_content}>*/}
                        {/*        <p>*/}
                        {/*            Адрес: ул. Казанская, 123, г. Казань, Республика Татарстан, Россия*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            Телефон: +7 (123) 456–78–90*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            Электронная почта: info@tamyrlanguage.com*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div style={{color: '#3D9246'}}>
                            © 2024 Проект «Тамыр/Тамырлар». Все права защищены.
                        </div>
                    </div>
                    <div className={styles.write_us}>
                        <div className={styles.write_us_1strow}>
                            <div className={styles.write_us_1strow_header}>
                                Обратная связь
                            </div>
                            <div className={styles.write_us_1strow_sign}>
                                Мы очень ценим ваше мнение и всегда рады услышать ваши предложения и отзывы о проекте.
                            </div>
                        </div>
                        <div className={styles.write_us_form}>
                            <input className={styles.write_us_form_mail} placeholder='Ваша почта' type="email"/>
                            <textarea className={styles.write_us_form_message} placeholder='Сообщение'/>
                        </div>
                        <img src="/send.png" alt="" className={styles.send_button}/>
                    </div>
                </div>
            </div>
        </main>

    );
};

export default Main;
