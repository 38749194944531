'use client';
import React, {useEffect, useState} from 'react';
import styles from './tests.module.scss';
import {Link, useNavigate} from "react-router-dom";
import second from "../../../config/second";



const Page = () => {

    const router = useNavigate();
    const [results, setResults] = useState<any>(null);
    const goBack = () => {
        router(-1);
    };

    const loadResults = async () => {
        let rawResults = await localStorage.getItem('@results')
        if ( rawResults) {
            let storage = JSON.parse(rawResults);
            if (storage.first) {
                setResults(storage)
            } else {
                setResults({first: {attempts: 0, correct: 0}})
            }
            if (storage.second) {
                setResults((old:any)=>({...old, second: storage.second}))
            } else {
                setResults((old:any)=>({...old, second: {attempts: 0, correct: 0}}))

            }
        }
    }

    useEffect(() => {
        loadResults()
    }, []);

    return (
        <div className={`background`}>
            <div className='content'>
                <div className='header'>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../Back.svg" className={`header_button`} alt="" onClick={goBack} style={{cursor: "pointer"}}/>
                            <img src="../тестлар2.svg" alt="" className={`${styles.header_button}`}/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap'>
                        <div className={`header_block ${styles.header_block} ${styles.flexcenter}`}>
                                <img src="../ico+test@2x 2.svg" alt="" style={{marginRight: '20px'}}/>
                                <img src="../Белемеңне сынап кара.svg" alt=""/>
                        </div>
                    </div>
                    <div className='header_block_border-wrap header_side'>
                        <div className={`header_block ${styles.header_block}`}>
                            <img src="../тестлар2.svg" alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.tests_block}>
                        <div className={`${styles.tests_block_inside} ${styles.tests_block_inside_pink}`}>
                            <div style={{display: 'flex'}}>
                                <div className={styles.round}>
                                    <img src="../image 10.png" alt=""/>
                                </div>
                                <img src="../Татар теле2.svg" className={`${styles.before1024}`} alt=""/>
                                <img src="../TatarTele.svg" className={`${styles.after1024}`} alt=""/>
                            </div>
                            <Link to='/tamyr/tests/testpage'  state={{ tests: 'first' }}>
                                <div className={`orange-button ${styles.orange_button}`}>
                                    <img src="../АЛГА.svg" alt=""/>
                                </div>
                            </Link>
                        </div>
                        <div className={styles.tests_block_bottom}>
                            <div className={styles.tests_block_bottom_stats}>
                                Статистика
                            </div>
                            <div style={{display: 'flex'}}>
                                <div className={styles.tests_block_bottom_text}>
                                    Үтелгән тестлар: {results?.first?.attempts || 0}
                                </div>
                                <div className={styles.tests_block_bottom_text}>
                                    Дөрес җаваплар: {results?.first?.correct/(results?.first?.attempts*10)*100 ? Math.round(results?.first?.correct/(results?.first?.attempts*10)*100) : 0}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tests_block}>
                        <div className={`${styles.tests_block_inside} ${styles.tests_block_inside_green}`}>
                            <div style={{display: 'flex'}}>
                                <div className={styles.round}>
                                    <img src="../logo2 2.png" alt=""/>
                                </div>
                                <img src="../Татар ӘДӘБИЯТЫ.svg" className={`${styles.before1024}`} alt=""/>
                                <img src="../TatarEdebiyati.svg" className={`${styles.after1024} ${styles.edebiyati}`} alt=""/>
                            </div>
                            <Link to='/tamyr/tests/testpage'  state={{ tests: 'second' }}>
                                <div className={`orange-button ${styles.orange_button}`}>
                                    <img src="../АЛГА.svg" alt=""/>
                                </div>
                            </Link>
                        </div>
                        <div className={styles.tests_block_bottom}>
                            <div className={styles.tests_block_bottom_stats}>
                                Статистика
                            </div>
                            <div style={{display: 'flex'}}>
                                <div className={styles.tests_block_bottom_text}>
                                    Үтелгән тестлар: {results?.second?.attempts || 0}
                                </div>
                                <div className={styles.tests_block_bottom_text}>
                                    Дөрес җаваплар: {results?.second?.attempts ? Math.round(results?.second?.correct/(results?.second?.attempts*10)*100) : 0}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
export {};
