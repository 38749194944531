import React from 'react';

// @ts-ignore
const CircleDiagram = ({ percent, playing, style, handlePress }) => {
    const circumference = 2 * Math.PI * 18.5;
    const strokeDashoffset = circumference - (percent / 100) * circumference;

    return (
        <div style={{...style, width: 48, height: 48, backgroundColor: '#00000000', borderRadius: 20}} onClick={handlePress}>
            <svg width="100" height="100" >
                {!playing && (
                    <>
                        <g>
                            <circle cx="22.5" cy="20.5" r="18.5" fill="#FFE144" />
                        </g>
                        <path
                            d="M41 20.5C41 30.7173 32.7173 39 22.5 39C12.2827 39 4 30.7173 4 20.5C4 10.2827 12.2827 2 22.5 2C32.7173 2 41 10.2827 41 20.5ZM8.17563 20.5C8.17563 28.4111 14.5889 34.8244 22.5 34.8244C30.4111 34.8244 36.8244 28.4111 36.8244 20.5C36.8244 12.5889 30.4111 6.17563 22.5 6.17563C14.5889 6.17563 8.17563 12.5889 8.17563 20.5Z"
                            fill="#FFF8C7"
                        />
                        <mask id="mask0_1_14" x="8" y="6" width="29" height="29">
                            <circle cx="22.5" cy="20.5" r="14.5" fill="#F8D51D" />
                        </mask>
                        <g mask="url(#mask0_1_14)">
                            <path
                                d="M22.4999 0.199997L23.0389 15.372L26.7205 0.643601L24.0933 15.5962L30.7567 1.95503L25.078 16.0346L34.432 4.07695L25.9501 16.6682L37.5858 6.91665L26.6714 17.4693L40.0802 10.35L27.2104 18.4028L41.8064 14.227L27.5435 19.428L42.6887 18.3781L27.6561 20.5L42.6887 22.6219L27.5435 21.572L41.8064 26.773L27.2104 22.5972L40.0802 30.65L26.6714 23.5307L37.5858 34.0834L25.9501 24.3318L34.432 36.923L25.078 24.9654L30.7567 39.045L24.0933 25.4038L26.7205 40.3564L23.0389 25.628L22.4999 40.8L21.961 25.628L18.2793 40.3564L20.9066 25.4038L14.2432 39.045L19.9218 24.9654L10.5679 36.923L19.0498 24.3318L7.41408 34.0834L18.3285 23.5307L4.91961 30.65L17.7895 22.5972L3.19348 26.773L17.4564 21.572L2.31113 22.6219L17.3437 20.5L2.31113 18.3781L17.4564 19.428L3.19348 14.227L17.7895 18.4028L4.91961 10.35L18.3285 17.4693L7.41408 6.91665L19.0498 16.6682L10.5679 4.07695L19.9218 16.0346L14.2432 1.95503L20.9066 15.5962L18.2793 0.643601L21.961 15.372L22.4999 0.199997Z"
                                fill="url(#paint0_radial_1_14)"
                            />
                        </g>
                        <path
                            d="M26 14.002C26 13.129 24.96 12.675 24.32 13.269L20.448 16.862C20.3556 16.948 20.2342 16.9959 20.108 16.996H17.5C17.1022 16.996 16.7206 17.1541 16.4393 17.4354C16.158 17.7167 16 18.0982 16 18.496V23.496C16 23.8939 16.158 24.2754 16.4393 24.5567C16.7206 24.838 17.1022 24.996 17.5 24.996H20.108C20.234 24.9959 20.3555 25.0434 20.448 25.129L24.32 28.723C24.96 29.316 26 28.863 26 27.99V14.002Z"
                            fill="url(#paint1_linear_1_14)"
                        />

                        <defs>
                            <radialGradient
                                id="paint0_radial_1_14"
                                cx="0"
                                cy="0"
                                r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(22.4999 20.5) rotate(90) scale(14.2583)"
                            >
                                <stop stopColor="white" />
                                <stop offset="1" stopColor="#FFF1A7" stopOpacity="0.25" />
                            </radialGradient>
                            <linearGradient
                                id="paint1_linear_1_14"
                                x1="21"
                                y1="13"
                                x2="21"
                                y2="28.9916"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#FE9316" />
                                <stop offset="0.392992" stopColor="#F7701D" />
                                <stop offset="1" stopColor="#CC5C16" />
                            </linearGradient>
                        </defs>
                    </>
                )}
                {playing && (
                    <>
                        <circle cx={24.5} cy={24.5} r={24.5} fill="url(#paint0_radial_529_134)" />
                        <circle cx={24.5} cy={24.5} r={18.5} fill="#FFE144" />
                        <path
                            d="M43 24.5C43 34.7173 34.7173 43 24.5 43C14.2827 43 6 34.7173 6 24.5C6 14.2827 14.2827 6 24.5 6C34.7173 6 43 14.2827 43 24.5ZM10.1756 24.5C10.1756 32.4111 16.5889 38.8244 24.5 38.8244C32.4111 38.8244 38.8244 32.4111 38.8244 24.5C38.8244 16.5889 32.4111 10.1756 24.5 10.1756C16.5889 10.1756 10.1756 16.5889 10.1756 24.5Z"
                            fill="#FFF8C7"
                        />
                        <path
                            d="M28 18.002C28 17.129 26.96 16.675 26.32 17.269L22.448 20.862C22.3556 20.948 22.2342 20.9959 22.108 20.996H19.5C19.1022 20.996 18.7206 21.1541 18.4393 21.4354C18.158 21.7167 18 22.0982 18 22.496V27.496C18 27.8939 18.158 28.2754 18.4393 28.5567C18.7206 28.838 19.1022 28.996 19.5 28.996H22.108C22.234 28.9959 22.3555 29.0434 22.448 29.129L26.32 32.723C26.96 33.316 28 32.863 28 31.99V18.002Z"
                            fill="url(#paint1_linear_529_134)"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M31.4661 18.1546C31.933 17.8596 32.5506 17.999 32.8456 18.466C34.4937 21.0752 35.3849 23.2115 35.3582 25.333C35.3312 27.4686 34.3772 29.3946 32.8136 31.5816C32.4924 32.0309 31.8678 32.1347 31.4185 31.8135C30.9693 31.4923 30.8654 30.8677 31.1867 30.4184C32.6752 28.3365 33.3392 26.8241 33.3583 25.3078C33.3776 23.7774 32.7427 22.0481 31.1547 19.5341C30.8597 19.0671 30.9992 18.4495 31.4661 18.1546Z"
                            fill="#FB8618"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M29.6062 21.3618C29.9587 21.1442 30.4208 21.2536 30.6383 21.6061C31.4329 22.8936 31.8853 23.9921 31.8718 25.1193C31.8582 26.2504 31.377 27.2752 30.6261 28.4131C30.398 28.7588 29.9328 28.8541 29.587 28.626C29.2413 28.3979 29.146 27.9327 29.3741 27.5869C30.0751 26.5247 30.3635 25.7966 30.3719 25.1013C30.3803 24.4021 30.1067 23.6007 29.3619 22.3939C29.1443 22.0414 29.2537 21.5793 29.6062 21.3618Z"
                            fill="#FB8618"
                        />
                        <defs>
                            <radialGradient
                                id="paint0_radial_529_134"
                                cx={0}
                                cy={0}
                                r={1}
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(24.5 24.5) rotate(90) scale(24.5)"
                            >
                                <stop />
                                <stop offset={1} stopOpacity={0} />
                            </radialGradient>
                            <linearGradient
                                id="paint1_linear_529_134"
                                x1={23}
                                y1={17}
                                x2={23}
                                y2={32.9916}
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#FE9316" />
                                <stop offset={0.392992} stopColor="#F7701D" />
                                <stop offset={1} stopColor="#CC5C16" />
                            </linearGradient>
                        </defs>
                        <circle
                            cx="24.5"
                            cy="24.5"
                            r="18.5" // Радиус круга с учетом толщины линии в 3 пикселя
                            fill="none"
                            stroke="#F7791D" // Цвет линии
                            strokeWidth="5" // Толщина линии
                            strokeDasharray={circumference}
                            strokeDashoffset={strokeDashoffset}
                        />
                    </>
                )}
            </svg>
        </div>
    );
};

export default CircleDiagram;